<template>
  <div>
    <b-row>
      <b-col md="12">
        <export-button :filter="exportFilter"
                       url="/transaction/export-report-game"
                       :disabled="loading.overlay || loading.input || !data.game.length"
                       file-title="Gaming_performance_Report"/>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{ $t("gaming_performance_report") }}</b-card-title>
          <b-row>
            <b-col md="3">

              <b-form-group
                  label="Date from"
                  label-for="datefrom"
                  rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                    v-model="dateFrom"
                    class="form-control"
                    :config="{
                    altInput: true,
                    altFormat: 'd-m-Y',
                    enableTime: false,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <flat-pickr
                    v-model="dateTo"
                    class="form-control"
                    :config="{
                    altInput: true,
                    altFormat: 'd-m-Y',
                    enableTime: false,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Game Name" label-for="gameName">
                <template #label>{{ $t('game_name') }}</template>
                <v-select
                    @option:selected="selectGame"
                    :options="games"
                    @search="onSearch"
                    :loading="loading.input"
                    :filterable="false"
                    :clearable="false"
                    :disabled="!(dateFrom && dateTo)"
                    label="gameName"
                >
                  <template slot="no-options">
                    {{ $t("type_a_letter_to_start_the_search") }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center overflow-hidden">
                      <div class="small lh-125 pb-1">
                        <div class="d-flex justify-content-between align-items-center w-100">
                          <strong class="text-gray-dark">{{ option.gameName }}</strong>
                        </div>
                        <span class="d-block ">{{ option.productId}}</span>
                      </div>
                    </div>
                  </template>

                  <template #list-footer>
                    <div v-if="games">
                      <b-button variant="light"
                                @click="prev()"
                                :disabled="page <= 0"
                                class="btn-prev w-50">
                        <feather-icon icon="ChevronLeftIcon"/>
                      </b-button>
                      <b-button variant="light"
                                :disabled="!games.length"
                                class="btn-next w-50"
                                @click="next()">
                        <feather-icon icon="ChevronRightIcon"/>
                      </b-button>
                    </div>
                  </template>

                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-overlay :show="loading.overlay">
            <b-table-simple hover
                            class="mt-2"
                            style="min-height: 350px"
                            small
                            caption-top
                            responsive>
              <b-thead head-variant="light">
                <b-tr>

                  <b-th v-b-tooltip.hover
                        :title="$t('interval_selected')">
                    {{ $t('interval') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="$t('game_id')">
                    Id
                  </b-th>


                  <b-th v-b-tooltip.hover
                        :title="$t('bet_total_amount')">
                    {{ $t('bet') }}
                  </b-th>


                  <b-th v-b-tooltip.hover
                        :title="$t('total_won')">
                    {{ $t('won') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="$t('total_contributed')">
                    {{ $t('contributed') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="$t('total_refunded')">
                    {{ $t('refunded') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="$t('total_remaining_funds')">
                    {{ $t('remaining_funds') }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                    :variant="tr._rowVariant"
                    v-for="(tr, indexTr) in data.game"
                    :key="indexTr">

                  <b-td class="text-noWrap">
                    {{ formatDate(tr.datein) }} - {{ formatDate(tr.dateout) }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.gameId }}
                  </b-td>


                  <b-td class="text-noWrap">
                    {{ tr.betsAmount | currency({symbol: ""}) }}
                    {{ currency }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.winsAmount | currency({symbol: ""}) }}
                    {{ currency }}
                  </b-td>


                  <b-td class="text-noWrap">
                    -
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.rollbackAmount  | currency({symbol: ""}) }}
                    {{ currency }}
                  </b-td>

                  <b-td class="text-noWrap">
                    -
                  </b-td>

                </b-tr>
              </b-tbody>
            </b-table-simple>

          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BCollapse,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BRow,
  BTable,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
} from "bootstrap-vue";
import "vue-tree-halower/dist/halower-tree.min.css";
import {VSelectTree, VTree} from "vue-tree-halower";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import {mapActions, mapState} from "vuex";
import Overlay from "@/views/components/overlay/Overlay.vue";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";
import store from "@/store";

export default {
  name: 'gamingPerformanceReport',
  components: {
    ExportButton,
    Overlay,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BTableSimple,
    VSelectTree,
    BThead,
    vSelect,
    VTree,
    BTr,
    BTh,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BTfoot,
    BTd,
    BTbody,
    flatPickr,
  },

  data() {

    return {
      games: [],
      gameSelected: null,
      loading: {
        overlay: false,
        input: false
      },
      data: {
        total: 0,
        game: []
      },

      search: '',

      limit: 10,
      page: 0,

      dateFrom: moment().subtract(1, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),

    };
  },


  watch: {
    limit: {
      handler() {
        this.setLoadingInput(true)
        this.getGames()
      }
    },

    dateFrom: {
      handler(value) {
        if (value && this.gameSelected) {
          this.getData()
        }
      }
    },

    dateTo: {
      handler(value) {
        if (value && this.gameSelected) {
          this.getData()
        }
      }
    },
  },

  computed: {
    ...mapState("whitelabelCurrencyNabvar", ["whitelabel", "currency"]),
    exportFilter(){
      return {
        datein: this.dateFrom,
        dateout: this.dateTo,
        currency: this.currency,
        gameId: this.gameSelected?.gameId,
        isHistorical: false,
        whitelabel: this.whitelabel._id
      }
    }
  },

  methods: {
    yearDropdownPlugin,
    ...mapActions("game", ["getGamePerformances", "getGamesLobby", "getGamesByWhitelabel"]),


    getData() {
      this.setLoadingOverlay(true)

      const filter = {
        datein: this.dateFrom,
        dateout: this.dateTo,
        currency: this.currency,
        gameId: this.gameSelected.gameId,
        isHistorical: false,
        whitelabel: this.whitelabel._id
      }


      this.getGamePerformances(filter).then(r => {
        this.data = r.data

      }).finally(() => {
        this.setLoadingOverlay(false)
      })

    },

    setLoadingOverlay(flag = false) {
      this.loading.overlay = flag
    },

    setLoadingInput(flag = false) {
      this.loading.input = flag
    },


    onSearch(search) {
      if (search.length) {
        this.search = search
        this.page = 0
        if (this.timeOut) clearTimeout(this.timeOut);

        this.timeOut = setTimeout(() => {
          this.getGames()
        }, 500);
      }
    },

    getGames() {
      this.setLoadingInput(true)

      const filter = {
        q: this.search.trim(),
        perPage: this.limit,
        sortBy: 'gameName',
        sortDesc: false,
        page: this.page
      }
      
      this.getGamesByWhitelabel(
          {
            filter,
            payload: {
              whiteLabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id
            }
          })
          .then(r => {
        this.games = r.data.games.map(gameObject => {
          return {...gameObject.game, productId: gameObject.productId}
        })

        console.log(this.games[0])

        this.setLoadingInput(false)
      }).catch(() => {
        this.setLoadingInput(false)
      })
    },


    formatDate(value) {
      return value ? moment(value).format('DD/MM/YYYY') : '-'
    },

    selectGame(game) {
      this.gameSelected = game
      this.getData()
    },

    next() {
      this.page += 1
      this.getGames()
    },

    prev() {
      this.page -= 1
      this.getGames()
    }

  },


};

</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}

.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}

.loader {
  text-align: center;
  color: #bbbbbb;
}

.btn-prev, .btn-next {
  border-radius: 0;
}

</style>
